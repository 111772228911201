export const DefaultlanguageCode: string = "zh"
export const languageCodeData: { [code: string]: { name: string, enName: string, localName: string } } = {
    "zh": { name: "繁體中文", enName: "Traditional Chinese", localName: "繁體中文" },
    "auto": { name: "Auto", enName: "Auto", localName: "Auto" },
    "en": { name: "英語", enName: "English", localName: "English" },
    "ja": { name: "日語", enName: "Japanese", localName: "日本語" },
    "ko": { name: "韓語", enName: "Korean", localName: "한국어" },
    "th": { name: "泰語", enName: "Thai", localName: "ภาษาไทย" },
    "vi": { name: "越南語", enName: "Vietnamese", localName: "Tiếng Việt" },
    "id": { name: "印尼語", enName: "Indonesian", localName: "Bahasa Indonesia" },
    "fr": { name: "法語", enName: "French", localName: "Français" },
    "de": { name: "德語", enName: "German", localName: "Deutsch" },
    "es": { name: "西班牙語", enName: "Spanish", localName: "Español" },
    "pt": { name: "葡萄牙語", enName: "Portuguese", localName: "Português" },
    "it": { name: "義大利語", enName: "Italian", localName: "Italiano" },
    "ru": { name: "俄語", enName: "Russian", localName: "Русский" },
    "ar": { name: "阿拉伯語", enName: "Arabic", localName: "العربية" },
};