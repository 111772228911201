import * as R from "remeda"
import Icon from "Images/banner.png"
import { useGetApiAppKeyList } from "@/API"
import { useAuthStore } from "@/Stores/AuthStore"
import { Image, Anchor, Box, Button, Card, Center, Group, Loader, LoadingOverlay, Paper, Stack, Text } from "@mantine/core"
import { useOs } from "@mantine/hooks"
import { useClipboard } from '@mantine/hooks';
import { notifications } from "@mantine/notifications"
import { LoginButton, LoginPage, LogoutButton } from "@/Auth/LoginPage"
import { Link } from "@tanstack/react-router"
import { useQueryClient } from "@tanstack/react-query"
import { IconLogin } from "@tabler/icons-react"
import { useEffect } from "react"

export function SelectAppKeyPage() {
    const queryClient = useQueryClient()
    var auth = useAuthStore()
    var list = useGetApiAppKeyList()
    const clipboard = useClipboard({ timeout: 500 });
    useEffect(() => {
        list.refetch()
    }, [auth.data.userName])

    return <Box pos="relative" h="100%" mih="100vh" bg="#f4f4f4" pt={50} pb={50}>
        <LoadingOverlay
            visible={auth.isUpdating}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Group justify="center" mb={20}>
            <Link to='/'>
                <Image
                    radius="md"
                    src={Icon}
                    w={300}
                />
            </Link>
        </Group>

        {auth.data.role == "admin" ? <Box m={10}>
            <Text>管理員無法使用授權金鑰，請切換成使用者後再試一次</Text>
            <LogoutButton variant="outline" w={200} navigateoptions={{ to: "/nav/SelectAppKey" }} />
        </Box> : <>
            {auth.data && auth.data.userName ? <>
                <Stack align="center" gap={10}>
                    <Text fw={500} size="xl">選擇授權金鑰</Text>
                    <Stack gap={0} align="center">
                        <Text size="sm" c="gray">點選後將複製金鑰、跳轉回應用程式自動設定</Text>
                        <Text size="sm" c="gray">（若無跳轉，請手動貼上設定）</Text>
                    </Stack>
                    <Group gap={10} align="center">
                        <Text size="md">{auth.data.displayName ? `${auth.data.displayName} (${auth.data.userName})` : auth.data.userName}</Text>
                        <Group gap={0}>
                            <Text size="md" c="gray">不是你？</Text>
                            <LoginButton openOnStart={false} redirect="/nav/SelectAppKey" >
                                {
                                    ({ opened, open }) =>
                                        <Anchor size="md" onClick={open} >
                                            切換帳號
                                        </Anchor>

                                }
                            </LoginButton>
                        </Group>
                    </Group>
                </Stack>
                <br />
                <Stack align="center">
                    {list.isSuccess ? list.data.data.map((x, idx) =>
                        <Paper maw="600px" w="100%" key={`appkey_${idx}`} shadow="sm">
                            <Center h="100%" w="100%">
                                <Button variant="subtle" w="100%" px={50} h={100} onClick={() => {
                                    clipboard.copy(x.keyId!)
                                    notifications.show({ message: "已複製授權金鑰至剪貼簿，請至APP輸入金鑰" })
                                    window.location.assign(`cakescribe://?appkey=${x.keyId!}`)
                                }}>
                                    <Stack gap={10}>
                                        <Text ta="center" fw={700} truncate="end" size="25px" variant="gradient" gradient={{ from: '#03BDB2', to: '#9851F5', deg: 90 }}>
                                            {!R.isEmpty(x.comment ?? "") ? x.comment : x.keyId}
                                        </Text>
                                        <Text ta="center" c="gray" truncate="end" style={{ fontSize: "15px" }} >
                                            {x.keyId}
                                        </Text>
                                    </Stack>
                                </Button >
                            </Center>
                        </Paper>
                    ) : <Center><Loader size={80} m={50} /></Center>}
                </Stack> </> : <>
                <Center>
                    <Card shadow="sm" padding="lg" radius="md" withBorder w={500}>
                        <LoginPage redirect="/nav/SelectAppKey" onLogin={() => { queryClient.invalidateQueries({ queryKey: list.queryKey }) }} />
                    </Card>
                </Center>
            </>}
        </>
        }
    </Box>
}