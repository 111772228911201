import { AudioRecordEntry } from "@/API"
import { languageCodeData } from "@/Helper/languageCodeData";
import { SecondToString } from "@/Helper/string";
import { Button, Group, Select, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PropsWithChildren } from "react";

export function RetranscribeEntryForm(props: { entry: AudioRecordEntry, onSubmit: (entry: AudioRecordEntry, langaugeCode: string) => void } & PropsWithChildren) {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            languageCode: props.entry.language ?? "auto",
        }
    });

    return <form onSubmit={form.onSubmit(async (values) => {
        props.onSubmit(props.entry, values.languageCode)
    })}>
        <Text c="var(--mantine-primary-color-filled)">
            是否要重新轉錄{SecondToString(props.entry.time ?? 0)}~{SecondToString((props.entry.time ?? 0) + (props.entry.duration ?? 0))}
        </Text>
        <Select
            label="選擇音檔語言"
            allowDeselect={false}
            {...form.getInputProps('languageCode')}
            data={Object.keys(languageCodeData).map(x => {
                return {
                    label: languageCodeData[x].name.toLowerCase() != "auto" ? languageCodeData[x].name : "自動",
                    value: x
                }
            })}
        />
        <Text c="gray" size="xs">
            重新轉錄會消耗剩餘時數
        </Text>
        <Group justify="flex-end" mt="md">
            <Button type="submit">確定</Button>
        </Group>
    </form>

}