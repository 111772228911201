import { AudioRecordEntry, PostApiWorkspaceCreateParams } from '@/API';
import { LanguageSelect } from '@/Components/LanguageSelect';
import { languageCodeData } from '@/Helper/languageCodeData';
import { Button, Checkbox, Group, NumberInput, Stack, Textarea, TextInput, Text, Switch, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMemo } from 'react';
import * as R from "remeda";
export function EditRecordEntryForm(props: { initValue: AudioRecordEntry, selectLanguage?: string, onSubmit: (data: AudioRecordEntry, autoTranslate: boolean) => void }) {
    const form = useForm<{
        minute: number,
        second: number,
        endMinute: number,
        endSecond: number,
        language: string,
        text: string,
        translatedText: string,
        autoTranslate: boolean,
    }>({
        initialValues: {
            minute: Math.floor((props.initValue.time ?? 0) / 60),
            second: Math.floor((props.initValue.time ?? 0) % 60),
            endMinute: Math.floor(((props.initValue.time ?? 0) + (props.initValue.duration ?? 0)) / 60),
            endSecond: Math.floor(((props.initValue.time ?? 0) + (props.initValue.duration ?? 0)) % 60),
            language: props.initValue.language ?? "",
            text: props.initValue.text ?? "",
            translatedText: props.initValue.translatedTexts?.find(x => x.language == props.selectLanguage)?.text ?? "",
            autoTranslate: false
        },
    });
    var language = useMemo(() => {
        return languageCodeData[props.selectLanguage ?? ""]?.name
    }, [props.selectLanguage])

    return (
        <form onSubmit={form.onSubmit((values) => {
            var data = R.clone(props.initValue)
            data.time = values.minute * 60 + values.second
            data.duration = values.endMinute * 60 + values.endSecond - data.time
            data.language = values.language
            data.text = values.text
            if (!data.translatedTexts) {
                data.translatedTexts = []
            }
            if (props.selectLanguage) {
                var index = data.translatedTexts?.findIndex(x => x.language == props.selectLanguage) ?? -1
                if (index >= 0)
                    data.translatedTexts[index].text = values.translatedText
                else
                    data.translatedTexts.push({ language: props.selectLanguage, text: values.translatedText })
            }
            props.onSubmit(data, values.autoTranslate);
        })}>
            <div style={{ overflowY: 'hidden' }}>
                <Stack gap={0}>
                    <Text size="md" fw={500}>開始時間</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <NumberInput
                            {...form.getInputProps('minute')}
                            decimalScale={0}
                            label="分"
                            min={0}
                            max={99}
                            style={{ marginRight: 10 }}
                        />
                        <NumberInput
                            {...form.getInputProps('second')}
                            decimalScale={0}
                            label="秒"
                            min={0}
                            max={99}
                        />
                    </div>
                    <br />
                    <Text size="md" fw={500}>結束時間</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <NumberInput
                            {...form.getInputProps('endMinute')}
                            decimalScale={0}
                            label="分"
                            min={0}
                            max={99}
                            style={{ marginRight: 10 }}
                        />
                        <NumberInput
                            {...form.getInputProps('endSecond')}
                            decimalScale={0}
                            label="秒"
                            min={0}
                            max={99}
                        />
                    </div>
                    <br />
                    <Group>
                        <Group gap={0}>
                            <Text size="md" fw={500}>轉錄語言</Text>
                            <Tooltip label="轉錄使用的語言，通常在轉錄時自動偵測語言。此設定只會影響翻譯時的品質。"
                            multiline color="gray" events={{ hover: true, focus: true, touch: true }}>
                                <IconInfoCircle color='var(--mantine-primary-color-filled)' size={20} />
                            </Tooltip>
                        </Group>
                        <LanguageSelect defaultLanguage={props.initValue.language ?? ""} languageData={Object.keys(languageCodeData).filter(x => x != "auto")}
                            onSelectLanguage={l => {
                                form.getInputProps('language').onChange(l)
                            }} />
                    </Group>
                    <Textarea
                        {...form.getInputProps('text')}
                        label="文字"
                        rows={5}
                    />
                    {!form.getValues().autoTranslate && props.selectLanguage &&
                        <>
                            <br />
                            <Textarea
                                {...form.getInputProps('translatedText')}
                                label={`翻譯文字(${language})`}
                                rows={5}
                            />
                        </>
                    }
                    {<Switch
                        label="自動翻譯"
                        style={{ marginTop: 10 }}
                        {...form.getInputProps('autoTranslate')}
                    />}
                </Stack>
                <div style={{ marginTop: 20, textAlign: 'right' }}>
                    <Button type="submit" color="var(--mantine-primary-color-filled)">
                        確認
                    </Button>
                </div>
            </div>
        </form>
    );
}