import { Box, Text } from "@mantine/core";
import { SourceList } from "./SourceList";
import { WeightList } from "./WeightList";
import { SearchAudioToTextRecord } from "./SearchAudioToTextRecord";
import { PageTitle } from "@/Components/PageTitle";

export function AudioToTextBalancer() {
    return <Box>
        <PageTitle text="外部服務狀態" />
        <br />
        <Text fz="h4">
            服務列表
        </Text>
        <SourceList />
        <Text fz="h4" mt={20}>
            當前權重
        </Text>
        <WeightList />
        <Text fz="h4" mt={20}>
            狀態查詢
        </Text>
        <SearchAudioToTextRecord />
    </Box>
}