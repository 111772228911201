import * as Y from 'yjs'
import { AudioRecord, TextDocument, useGetApiSummarizationTemplateList } from "@/API";
import { Button, Text, Modal, Group, Box, Center } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import { IconNotes } from "@tabler/icons-react";
import { PropsWithChildren, useMemo, useRef, useState } from "react";
import { CreateSummary, CreateSummaryTemplate } from "./CreateSummaryTemplate";
import { RichTextEditor } from "@mantine/tiptap";
import { Editor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import AudioRecordTime from "../AudioRecordTimeExtension";
import Collaboration from "@tiptap/extension-collaboration";
import { LanguageSelect } from '@/Components/LanguageSelect';
import { BuildLocationFn } from '@tanstack/react-router';
import { DefaultlanguageCode, languageCodeData } from '@/Helper/languageCodeData';


export function ChooseSummaryTemplateButton(props: { audioRecord: AudioRecord, onProgress?: (progress: number) => void, onEnd?: (doc?: TextDocument) => void } & PropsWithChildren) {
    const [opened, { open, close }] = useDisclosure(false);
    const summaryTemplateNames = useGetApiSummarizationTemplateList({ languageCode: "zh" })
    const yDoc = useRef<Y.Doc>(new Y.Doc())
    const editor = useEditor({
        extensions: [
            StarterKit,
            Markdown.configure({
                html: true
            }),
            AudioRecordTime,
            Collaboration.configure({
                document: yDoc.current,
            }),
        ],
    });
    const entries = useMemo(() => {
        return summaryTemplateNames.data?.data.map<ChooseSummaryTemplateEntry>(x => {
            return {
                title: x.info?.title ?? "",
                text: x.info?.description ?? "",
                onTrigger: async languageCode => {
                    close()
                    props.onProgress?.(0)
                    try {
                        if (editor && x.id) {
                            var doc = await CreateSummaryTemplate(editor, yDoc.current, x.id, props.audioRecord, languageCode, x.info?.title ?? "摘要", props.onProgress)
                            props.onEnd?.(doc)
                        }
                    } catch (e) {
                        props.onEnd?.(undefined)
                    }
                }
            }
        }) ?? []
    }, [summaryTemplateNames.data?.data, props.audioRecord, props.onProgress, props.onEnd])
    return <>
        <div onClick={open}>
            {props.children}
        </div>
        <RichTextEditor hidden={true} editor={editor}>
            <>
            </>
        </RichTextEditor>
        <Modal opened={opened} onClose={close} title="選擇摘要模板" radius="lg" size="lg">
            {editor &&
                <ChooseSummaryTemplate entries={[{
                    title: "段落重點摘要", text: "最全面的分段摘要，依序歸結會議或講座的重點，附有時間戳記。", onTrigger: async (language) => {
                        close()
                        props.onProgress?.(0)
                        try {
                            var doc = await CreateSummary(editor, yDoc.current, props.audioRecord, language, props.onProgress)
                            props.onEnd?.(doc)
                        } catch (e) {
                            props.onEnd?.(undefined)
                        }
                    }
                }, ...entries]} />
            }
        </Modal>
    </>
}

interface ChooseSummaryTemplateEntry {
    title: string,
    text: string,
    onTrigger?: (languageCode: string) => void
}
export function ChooseSummaryTemplate(props: { entries: ChooseSummaryTemplateEntry[] }) {
    const [id, setId] = useState(-1)
    const [languageCode, setLanguageCode] = useState<string | null>("zh")

    return <>
        {/* <LanguageSelect languageData={Object.keys(languageCodeData).filter(x => x != "auto")} defaultLanguage={language} onSelectLanguage={setLanguage} />
        <br /> */}
        <Group>
            {props.entries.map((entry, idx) => {
                return <ChooseSummaryTemplateCard key={`${idx}`} isSelect={idx == id} onClick={() => {
                    setId(idx)
                }} title={entry.title} text={entry.text} />
            })}
        </Group>
        <Center mt={20}>
            <Button size="compact-sm" disabled={id < 0} onClick={() => {
                if (id >= 0) {
                    props.entries[id].onTrigger?.(languageCode ?? DefaultlanguageCode)
                }
            }}>生成摘要</Button>
        </Center>
    </>
}
function ChooseSummaryTemplateCard(props: { isSelect: boolean, title: string, text: string, onClick: () => void }) {
    return <>
        <Button onClick={props.onClick} mih={120} variant={props.isSelect ? "light" : "subtle"} radius="lg" p={10}>
            <Box style={{ whiteSpace: "pre-wrap" }} w={160} h="100%">
                <Group gap={5}>
                    <IconNotes size={20} color="var(--mantine-primary-color-filled)" />
                    <Text c="black" size="sm">
                        {props.title}
                    </Text>
                </Group>
                <Text c="gray" size="xs" ta="start">
                    {props.text}
                </Text>
            </Box>
        </Button>
    </>
}