import { usePostApiAdminWorkspaceSearch, Workspace, WorkspaceSearchListResult } from "@/API";
import { PageTitle } from "@/Components/PageTitle";
import { ActionIcon, Box, Button, Group, TextInput, Text, Pagination } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconEdit } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { useCallback, useEffect, useState } from "react";
import { AdminSetOwnerButton } from "./AdminSetUserOwnerRoleButton";

export function WorkspaceManager() {
    const searchWorkpsace_Mutation = usePostApiAdminWorkspaceSearch()
    const form = useForm({
        initialValues: {
            nameQuery: '',
            userQuery: '',
        }
    });
    const [workspaceListResult, setWorkspaceListResult] = useState<WorkspaceSearchListResult>()
    const [activePage, setPage] = useState(1);
    useEffect(() => {
        updateList()
    }, [activePage])
    const PageSize = 10
    var updateList = async () => {
        var result = await searchWorkpsace_Mutation.mutateAsync({
            params: {
                ...form.values,
                skip: (activePage - 1) * PageSize,
                limit: PageSize
            },
        })
        setWorkspaceListResult(result.data)
    }
    return <>
        <PageTitle text="工作空間管理" />
        <br />
        <form onSubmit={form.onSubmit(async (values) => {
            if (activePage != 1)
                setPage(1)
            else
                updateList()
        })}>
            <TextInput
                label="工作空間名稱"
                placeholder="workspace name"
                {...form.getInputProps('nameQuery')}
            />
            <TextInput
                label="使用者名稱"
                placeholder="user name"
                {...form.getInputProps('userQuery')}
            />
            <Button type="submit" mt={10}>搜尋</Button>
        </form>
        <br />
        <Pagination value={activePage} onChange={setPage} total={Math.ceil((workspaceListResult?.total ?? 0) / PageSize)} />
        <DataTable
            withTableBorder
            columns={[
                { accessor: 'id' },
                { accessor: 'name', title: "工作空間名稱" },
                {
                    accessor: 'owner', title: "擁有者", render: (data) => (
                        <Text>
                            {data.users?.filter(x => x.role == "Owner")[0]?.userName ?? "None"}
                        </Text>)
                },
                {
                    accessor: 'count', title: "人數", render: (data) => (
                        <Text>
                            {data.users?.length}
                        </Text>)
                },
                {
                    accessor: 'actions',
                    title: <Box mr={6}>操作</Box>,
                    textAlign: 'right',
                    render: (data) => (
                        <Group gap={4} justify="right" wrap="nowrap">
                            <AdminSetOwnerButton workspaceId={data.id ?? ""} defaultUserNames={data.users?.map(x => x.userName ?? "")} onResult={updateList} >
                                <Button size="compact-xs">
                                    轉移擁有者
                                </Button>
                            </AdminSetOwnerButton>
                        </Group>
                    ),
                },
            ]}
            records={workspaceListResult?.results ?? []}
        />
    </>
}