import { languageCodeData } from "@/Helper/languageCodeData"
import { ComboboxItem, Select, SelectProps } from "@mantine/core"
import { useMemo, useState } from "react"

export function LanguageSelect(props: { selectProps?: SelectProps, defaultLanguage: string | null, languageData: string[], onSelectLanguage?: (language: string | null) => void }) {
    const [language, setLanguage] = useState<string | null>(props.defaultLanguage)
    var translatedTexts: ComboboxItem[] = useMemo(() => {
        var data = props.languageData.map<ComboboxItem>(x => {
            return {
                label: languageCodeData[x]?.name ?? "",
                value: x
            }
        })
        data = data.sort((a, b) => {
            var a1 = Object.keys(languageCodeData).findIndex(x => x == a.value) ?? -1
            var b1 = Object.keys(languageCodeData).findIndex(x => x == b.value) ?? -1
            return a1 - b1
        })
        return data;
    }, [languageCodeData])
    return <>
        <Select {...props.selectProps} clearable variant="filled" data={translatedTexts} value={language} onChange={x => {
            setLanguage(x)
            props.onSelectLanguage?.(x)
        }} />
    </>
}