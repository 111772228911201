import { getGetApiAudioRecordGetBaseQueryKey, useGetApiAudioRecordGet, useGetApiAudioRecordGetBase, useGetApiWorkspaceGet } from "@/API"
import { AudioRecordListCard } from "../Workspaces/Workspace/AudioRecordListCard"
import { useNavigate } from "@tanstack/react-router"
import { Box, Button, Text } from "@mantine/core"
import { IconFolder } from "@tabler/icons-react"
import { useRecentOpenAudioRecordStore } from "@/Stores/RecentOpenAudioRecordStore"
import { useEffect } from "react"

export function RecentOpenAudioRecordCard(props: { workspaceId: string, audioRecordId: string }) {
    const workspace = useGetApiWorkspaceGet({ id: props.workspaceId })
    const audioRecord = useGetApiAudioRecordGetBase({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }, {
        query: {
            queryKey: getGetApiAudioRecordGetBaseQueryKey({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }),
            retry: 1
        }
    })
    const navigate = useNavigate()
    const recentOpenAudioRecord = useRecentOpenAudioRecordStore()
    useEffect(() => {
        if (audioRecord.status == "error")
            recentOpenAudioRecord.Remove(props.audioRecordId)
    }, [audioRecord.status])
    return <>
        {workspace.data?.data && audioRecord.data?.data && <AudioRecordListCard editable={false} workspace={workspace.data?.data} audioRecord={audioRecord.data.data} onEnter={() => {
            navigate({
                to: "/workspace/$workspaceId/audioRecord/$audioRecordId", params: {
                    workspaceId: props.workspaceId,
                    audioRecordId: props.audioRecordId
                }
            })
        }} section={
            <Text size="sm">
                <Button variant="light" color="gray" size="compact-sm" leftSection={<IconFolder size={20} />} onClick={() => navigate({
                    to: "/workspace/$workspaceId", params: {
                        workspaceId: props.workspaceId
                    }
                })}>
                    {workspace.data.data.name}
                </Button>
            </Text>
        } />}
    </>
}