import { useAuthStore } from "@/Stores/AuthStore";
import { Text, Button, Space, Group, ActionIcon, CopyButton, Tooltip, rem, Checkbox, LoadingOverlay } from '@mantine/core';
import { ChangeDisplayNameDialog } from "./ChangeDisplayNameDialog";
import { useDisclosure } from "@mantine/hooks";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { PageTitle } from "@/Components/PageTitle";
import { IconCopy, IconEdit } from "@tabler/icons-react";
import { useGetApiUserGetSetting, usePutApiUserUpdateSetting } from "@/API";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";


export function SettingsPage() {
    var auth = useAuthStore();
    const queryClient = useQueryClient()
    const [ChangeDisplayName_opened, { open: ChangeDisplayName_open, close: ChangeDisplayName_close }] = useDisclosure(false);
    const [ChangePassword_opened, { open: ChangePassword_open, close: ChangePassword_close }] = useDisclosure(false);
    const [updateSettingLoading, setUpdateSettingLoading] = useState(false)
    const setting = useGetApiUserGetSetting()
    const updateSetting_Mutation = usePutApiUserUpdateSetting()
    return <>
        <PageTitle text="個人設定" />
        <br />
        <Group gap={20}>
            <Text fw={500} size="xl">
                帳號
            </Text>
            <Group gap={0}>
                <Text fw={500} size="xl">
                    {auth.data.userName}
                </Text>
                <CopyButton value={auth.data.userName ?? ""}>
                    {({ copied, copy }) => (
                        <Tooltip label={copied ? '已複製' : ''} disabled={!copied} withArrow>
                            <ActionIcon size="xl" variant="transparent" onClick={copy}>
                                <IconCopy style={{ width: rem(24), height: rem(24) }} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </CopyButton>
            </Group>
        </Group>
        <Group gap={20}>
            <Text fw={500} size="xl">
                名稱
            </Text>
            <Group gap={0}>
                <Text fw={500} size="xl">
                    {auth.data.displayName && auth.data.displayName != "" ? auth.data.displayName : "無"}
                </Text>
                <ActionIcon
                    size="xl"
                    variant="transparent"
                    onClick={ChangeDisplayName_open}
                >
                    <IconEdit />
                </ActionIcon>
            </Group>
        </Group>
        {setting.data?.data &&
            <Group gap={20}>
                <Text fw={500} size="xl">
                    允許加入工作空間
                </Text>
                <Group gap={0} pos="relative">
                    <Checkbox
                        disabled={updateSettingLoading}
                        checked={setting.data?.data.allowWorkspaceJoin}
                        onChange={async v => {
                            if (setting.data.data) {
                                setUpdateSettingLoading(true)
                                try {
                                    await updateSetting_Mutation.mutateAsync({
                                        data: {
                                            ...setting.data.data,
                                            allowWorkspaceJoin: v.target.checked
                                        }
                                    })
                                    queryClient.invalidateQueries({ queryKey: setting.queryKey })
                                    notifications.show({ message: "更新設定成功" })
                                } catch (e) {
                                    notifications.show({ message: "更新設定失敗", color: "red" })
                                }
                                setUpdateSettingLoading(false)
                            }
                        }}
                    />
                    <Text ml={5}>
                        允許
                    </Text>
                    <Text ml={10} c="gray" size="sm">
                        是否允許被其他使用者加入到工作空間
                    </Text>
                </Group>
            </Group>
        }
        {auth.data.provider == "System" &&
            <Button color="green" mt={10} onClick={ChangePassword_open}>修改密碼</Button>
        }

        <ChangeDisplayNameDialog opened={ChangeDisplayName_opened} onClose={ChangeDisplayName_close} />
        {auth.data.provider == "System" &&
            <ChangePasswordDialog opened={ChangePassword_opened} onClose={ChangePassword_close} />}
    </>;
}
