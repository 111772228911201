import * as R from "remeda";
import { Center, Text, Image, Box, Group, ActionIcon, Tooltip } from "@mantine/core";
import Icon from "Images/banner_full.svg"
import { useGetLicense } from "../API";
import { useRecentOpenAudioRecordStore } from "../Stores/RecentOpenAudioRecordStore";
import { RecentOpenAudioRecordCard } from "./RecentOpenAudioRecordCard";
import { PageTitle } from "../Components/PageTitle";
import { IconPlaylistX } from "@tabler/icons-react";
export function Index() {
  const license = useGetLicense()
  const recentOpenAudioRecord = useRecentOpenAudioRecordStore()

  return (
    <Box p={20}>
      <Center>
        <Image
          radius="md"
          w={500}
          src={Icon}
        />
      </Center>
      <Center>
        <Text fw={700} style={{ fontSize: "25px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
          {license.data?.data.subject}
        </Text>
      </Center>
      <br />
      <Group gap={0} align="center">
        <PageTitle text="最近開啟逐字稿" />
        <Tooltip label="清除紀錄" color="gray">
          <ActionIcon variant="subtle" onClick={recentOpenAudioRecord.Clear}>
            <IconPlaylistX stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <br />
      {
        recentOpenAudioRecord.records.length > 0 ?
          <Group>
            {R.reverse(recentOpenAudioRecord.records).map((record, idx) =>
              <RecentOpenAudioRecordCard key={`AudioRecordPreviewCards_${idx}`} workspaceId={record.workspaceId} audioRecordId={record.audioRecordId} />
            )}
          </Group> : <>
            <Text c="gray">
              最近尚無開啟逐字稿
            </Text>
          </>}
    </Box>
  )
}