import { WorkspaceUserRole } from "@/API";
import { Button, Group, Modal, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { PropsWithChildren } from "react";
import { WorkspaceUserRoleToText } from "../../WorkspaceUserRoleText";

export function SelectWorkspaceUserRoleButton(props: { title?: string, initValue: WorkspaceUserRole, options: WorkspaceUserRole[], onConfirm: (value: WorkspaceUserRole) => void } & PropsWithChildren) {
    const [opened, { open, close }] = useDisclosure(false);

    return <>
        <span onClick={open}>
            {props.children}
        </span>
        <Modal opened={opened} onClose={close} title={props.title} centered>
            <WorkspaceUserRoleForm initValue={props.initValue} options={props.options} onConfirm={(v) => {
                props.onConfirm(v);
                close();
            }} />
        </Modal>
    </>
}
export function WorkspaceUserRoleForm(props: { initValue: WorkspaceUserRole, options: WorkspaceUserRole[], onConfirm: (value: WorkspaceUserRole) => void }) {
    const form = useForm<{ value: WorkspaceUserRole }>({
        mode: 'uncontrolled',
        initialValues: {
            value: props.initValue,
        },
    });
    return <form onSubmit={form.onSubmit((values) => props.onConfirm(values.value))}>
        <Select
            label="使用者權限"
            allowDeselect={false}
            data={props.options.map(x => {
                return { value: x, label: WorkspaceUserRoleToText(x) }
            })}
            {...form.getInputProps("value")}
        />
        <Group justify="flex-end" mt="md">
            <Button type="submit">確認</Button>
        </Group>
    </form>
}