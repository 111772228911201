import { useGetApiAdminSystemTotalMediaSize } from "@/API"
import { PageTitle } from "@/Components/PageTitle"
import { BytesToString } from "@/Helper/string"
import { Loader, Paper, Text } from "@mantine/core"

export function AdminInfoIndex() {
    var size = useGetApiAdminSystemTotalMediaSize()
    return <>
        <PageTitle text="系統資訊" />
        <br />
        <Paper withBorder shadow="xs" p="md">
            <Text>媒體總計大小</Text>
            <Text>
                {size.data?.data != undefined ? BytesToString(size.data?.data) : <Loader />}
            </Text>
        </Paper>
    </>
}
