import * as R from "remeda"
import dayjs from "dayjs";
import 'dayjs/locale/zh-tw';
import { PostApiAdminSttUsageLogSearchParams, postApiAdminSttUsageLogSearch } from '@/API';
import { get_random_hue_color } from '@/RandomColor';
import { Box, Button, Group, Text, TextInput } from "@mantine/core"
import { BarChart, BarChartSeries } from '@mantine/charts';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from "@mantine/form";
import { DateTimePicker } from "@mantine/dates";
import { useSearch } from "@tanstack/react-router";
import { PageTitle } from "@/Components/PageTitle";
export function SttUsageAdminIndex() {
    const { userId } = useSearch({ from: "/admin/TTSUsage" })
    var [loading, setLoading] = useState(false)
    var [data, setData] = useState<Record<string, any>[]>()
    var [series, setSeries] = useState<BarChartSeries[]>()
    const form = useForm<PostApiAdminSttUsageLogSearchParams>({
        initialValues: {
            userId: userId,
            startTime: dayjs().add(-1, "month").toISOString(),
            endTime: dayjs().toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
    });
    return <Box>
        <PageTitle text="使用狀況查詢" />
        <br />
        <form onSubmit={form.onSubmit(async (values) => {
            setLoading(true)
            try {
                var logs = await postApiAdminSttUsageLogSearch(values)
                setLoading(false)
            } catch (e) {
                setData(undefined)
                setSeries(undefined)
                setLoading(false)
                return;
            }
            var _series: Set<string> = new Set(["unkonwn"]);
            var result: any = {}
            result = logs.data.map(x => {
                var d = { time: dayjs(x.time!).toDate().toLocaleDateString() }
                x.usages?.forEach(usage => {
                    if (usage.usage) {
                        d = {
                            ...d,
                            [usage.appKey ?? "unkonwn"]: R.round(usage.usage, 1)
                        }
                        if (usage.appKey)
                            _series.add(usage.appKey)
                    }
                })
                return d
            })
            setData(result)
            setSeries([..._series].map((key) => {
                return {
                    name: key,
                    color: get_random_hue_color(50, 60)
                }
            }))
        })}>
            <TextInput
                withAsterisk
                label="使用者ID"
                {...form.getInputProps("userId")}
            />
            <DateTimePicker
                label="開始時間"
                locale="zh-tw"
                valueFormat="YYYY/MM/DD HH:mm"
                value={dayjs(form.getValues().startTime).toDate()}
                onChange={x => form.setFieldValue("startTime", x?.toISOString())}
            />
            <DateTimePicker
                rightSectionWidth={100}
                rightSectionProps={{ style: { justifyContent: "flex-end" } }}
                rightSection={<Button onClick={() => form.setFieldValue("endTime", new Date().toISOString())}>當前時間</Button>}
                label="結束時間"
                locale="zh-tw"
                valueFormat="YYYY/MM/DD HH:mm"
                value={dayjs(form.getValues().endTime).toDate()}
                onChange={x => form.setFieldValue("endTime", x?.toISOString())}
            />
            <Group justify="flex-start" mt="md">
                <Button loading={loading} type="submit">搜尋</Button>
            </Group>
        </form>
        <Box m="md">
            {data && series ?
                <BarChart
                    h={300}
                    data={data}
                    dataKey="time"
                    type="stacked"
                    series={series}
                    unit="秒"
                /> : <>
                    目前尚無資料
                </>}
        </Box>
    </Box>
}