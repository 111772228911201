import * as R from "remeda";
import { useDeleteApiAdminPeriodCreditKeyDelete, useGetApiAdminPeriodCreditKeyList } from "@/API"
import { ActionIcon, Box, Button, Group, Text, Tooltip } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { notifications } from "@mantine/notifications"
import { IconClockHour3, IconEdit, IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { DataTable } from "mantine-datatable"
import { AddPeriodCreditKeyDialog } from "./AddPeriodCreditKeyDialog";
import { useState } from "react";
import { EditPeriodCreditKeyDialog } from "./EditPeriodCreditKeyDialog";
import { IncreasePeriodCreditKeyDialog } from "./IncreasePeriodCreditKeyDialog";
import { PaymentDetailsButton } from "@/Admin/LinePay";

export function PeriodCreditKeyList({ userId }: { userId: string }) {
    const queryClient = useQueryClient()
    const [createKey_opened, { open: createKey_open, close: createKey_close }] = useDisclosure(false);
    const [editKey_opened, { open: editKey_open, close: editKey_close }] = useDisclosure(false);
    const [increaseKey_opened, { open: increaseKey_open, close: increaseKey_close }] = useDisclosure(false);
    const [selectedKeyId, setSelectedKeyId] = useState("")
    const list = useGetApiAdminPeriodCreditKeyList({ userId: userId })
    const deleteKey_Mutation = useDeleteApiAdminPeriodCreditKeyDelete()
    return <>
        <Button w={150} onClick={() => createKey_open()}>
            新增週期儲值金鑰
        </Button>
        {list.isSuccess && <>
            <DataTable
                minHeight={list.data.data.length > 0 ? 0 : 150}
                noRecordsText="沒有週期儲值金鑰"
                withRowBorders={false}
                columns={[
                    { accessor: "keyId", width: 300 },
                    { accessor: "startTime", width: 100, title: "開始時間", render: (data) => (new Date(Date.parse(data.startTime!)).toLocaleDateString()) },
                    { accessor: "expiryTime", width: 200, title: "到期時間", render: (data) => (new Date(Date.parse(data.expiryTime!)).toLocaleString()) },
                    { accessor: "balance", width: 150, title: "剩餘時數", render: (data) => (`${R.round((data.balance ?? 0) / 3600, 2)}(${R.round((data.balance ?? 0), 1)}秒)`) },
                    {
                        accessor: "comment", title: "註記", render: (data) => <Tooltip style={{ whiteSpace: "pre-wrap" }} label={data.comment} multiline><Text variant="text" w={100} fw={300} truncate="end">{data.comment}</Text></Tooltip>
                    },
                    {
                        accessor: "sourceType", width: 80, title: "來源類型"
                    },
                    {
                        accessor: "source", width: 180, title: "來源", render: (data) => <>
                            {data.source}
                            {data.sourceType == "linepay" && <PaymentDetailsButton id={data.source!} />}
                        </>
                    },
                    {
                        accessor: 'actions',
                        title: <Box mr={6}>操作</Box>,
                        textAlign: 'right',
                        render: (data, idx) => (
                            <Group gap={4} justify="right" wrap="nowrap">
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    onClick={() => {
                                        if (data.keyId) {
                                            setSelectedKeyId(data.keyId)
                                            increaseKey_open()
                                        }
                                    }}
                                >
                                    <IconClockHour3 size={16} />
                                </ActionIcon>
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    onClick={() => {
                                        if (data.keyId) {
                                            setSelectedKeyId(data.keyId)
                                            editKey_open()
                                        }
                                    }}
                                >
                                    <IconEdit size={16} />
                                </ActionIcon>
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    color="red"
                                    onClick={() => {
                                        modals.openContextModal({
                                            modal: "ConfirmCountDown",
                                            title: '刪除儲值金鑰',
                                            innerProps: {
                                                countDownSecond: 10,
                                                children: (
                                                    <Text size="sm">
                                                        是否要刪除儲值金鑰?
                                                    </Text>
                                                ),
                                                labels: { confirm: '刪除', cancel: '取消' },
                                                confirmProps: { color: 'red' },
                                                onConfirm: async () => {
                                                    try {
                                                        if (data.keyId) {
                                                            await deleteKey_Mutation.mutateAsync({ params: { userId: userId, keyId: data.keyId } })
                                                            queryClient.invalidateQueries({ queryKey: list.queryKey })
                                                            notifications.show({ message: "刪除成功" })
                                                        }
                                                    } catch (e) {
                                                        notifications.show({ message: "刪除失敗", color: "red" })
                                                    }
                                                },
                                            }
                                        });
                                    }}
                                >
                                    <IconTrash size={16} />
                                </ActionIcon>
                            </Group>
                        ),
                    }
                ]}
                records={list.data.data.map(x => { return { id: x.keyId, ...x } })}
            />
        </>}
        <AddPeriodCreditKeyDialog userId={userId} opened={createKey_opened} onClose={() => createKey_close()} />
        {selectedKeyId && <EditPeriodCreditKeyDialog userId={userId} keyId={selectedKeyId} opened={editKey_opened} onClose={() => editKey_close()} />}
        {selectedKeyId && <IncreasePeriodCreditKeyDialog userId={userId} keyId={selectedKeyId} opened={increaseKey_opened} onClose={() => increaseKey_close()} />}
    </>
}