import * as R from "remeda";
import { AuthState, SystemInfo } from '@/API'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface RecentOpenAudioRecord {
    workspaceId: string,
    audioRecordId: string
}
export interface RecentOpenAudioRecordStoreState {
    records: RecentOpenAudioRecord[]
    Add: (record: RecentOpenAudioRecord) => void
    Remove: (audioRecordId: string) => void
    Clear: () => void
}
const maxRecords = 4;
export const useRecentOpenAudioRecordStore = create<RecentOpenAudioRecordStoreState>()(
    devtools(
        persist(
            immer((set) => ({
                records: [],
                Add: (record: RecentOpenAudioRecord) =>
                    set((state) => {
                        state.records = state.records.filter(x => x.audioRecordId != record.audioRecordId)
                        state.records.push({
                            workspaceId: record.workspaceId, audioRecordId: record.audioRecordId
                        })
                        state.records = R.uniqueBy(state.records, x => x.audioRecordId)
                        while (state.records.length > maxRecords)
                            state.records.shift()
                    }),
                Remove: (audioRecordId: string) =>
                    set((state) => {
                        state.records = state.records.filter(x => x.audioRecordId != audioRecordId)
                    }),
                Clear: () => set(state => {
                    state.records = []
                })
            })), { name: "RecentOpenAudioRecord" }),
    )
)