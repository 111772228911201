import { Button, ButtonProps } from "@mantine/core";
import { useMatchRoute, UseMatchRouteOptions, useNavigate, RegisteredRouter, RoutePaths } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

export function NavigationButton(props: PropsWithChildren<{ match: UseMatchRouteOptions, onClickLink?: () => void, buttonProps?: ButtonProps }>) {
    const navigate = useNavigate()
    const matchRoute = useMatchRoute()
    return <>
        <Button w="100%" h={45} variant={matchRoute(props.match) ? "light" : "subtle"} color={matchRoute(props.match) ? "" : "gray"}
            onClick={() => {
                props.onClickLink?.();
                navigate({ to: props.match.to as RoutePaths<RegisteredRouter["routeTree"]> });
            }}
            style={{ display: "flex", flexDirection: "row" }} {...props.buttonProps}>
            {props.children}
        </Button>
    </>
}