import * as R from "remeda";
import { AnimatePresence, motion, useIsPresent } from "motion/react";
import { AudioRecordEntry, DataUpdateMeta, useGetApiAudioRecordGet } from "@/API";
import { UseSignalrState } from "@/Helper/UseSignalrState";
import { Box, Button, Center, ColorInput, Flex, Group, Loader, NumberInput, Paper, Select, Stack, Switch, Text, Transition } from "@mantine/core"
import { useHover, useInterval } from "@mantine/hooks";
import * as signalR from "@microsoft/signalr";
import { IconArrowBackUp, IconLanguageHiragana, IconRestore, IconTextSize } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router"
import { PropsWithChildren, RefObject, useEffect, useMemo, useState } from "react";
import { TranslatedLanguageSelect } from "../AudioRecordItem/TranslatedLanguageSelect";
import { useAudioRecordSubtitleSettingStore } from "./AudioRecordSubtitleSetting";

export function AudioRecordSubtitle() {
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle" })
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [hubConnection, setHubConnection] = useState<signalR.HubConnection | null>(null);
    const { SetConnection, connectionState } = UseSignalrState()
    const settingStore = useAudioRecordSubtitleSettingStore();
    // const [maxTextCount, setMaxTextCount] = useState<number>(200);
    // const [backgroundColor, setBackgroundColor] = useState('');
    // const [fontSize, setFontSize] = useState(32)
    // const [translateFontSize, setTranslateFontSize] = useState(24)
    const [dropDownOpenCount, setDropDownOpenCount] = useState(0)
    // const [realtimeText, setRealtimeText] = useState("")
    const [translatedLanguage, setTranslatedLanguage] = useState<string | null>(null)
    // const [useRealtimeText, setUseRealtimeText] = useState(false);
    // const [useCorrectionText, setUseCorrectionText] = useState(true);
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const recordEntries = useMemo(() => {
        return audioRecord.data?.data.recordEntries?.sort((a, b) => (a.time ?? 0) - (b.time ?? 0)) ?? []
    }, [audioRecord.data?.data])
    var translatedTexts = useMemo(() => {
        return R.unique(R.flatMap(recordEntries, x => x.translatedTexts).map(x => x?.language)).filter(x => !!x)
    }, [recordEntries])
    const [recording_heartbeat, set_recording_heartbeat] = useState(0)
    useEffect(() => {
        if (audioRecord.data?.data.metadata?.recordingHeartbeatTimestamp) set_recording_heartbeat(audioRecord.data?.data.metadata?.recordingHeartbeatTimestamp)
    }, [audioRecord.data?.data.metadata?.recordingHeartbeatTimestamp])
    const isRecording = useMemo(() => {
        return (Date.now() / 1000) - recording_heartbeat <= 60
    }, [recording_heartbeat])
    // const textData = useMemo(() => {
    //     var result = ""
    //     var translated = ""
    //     if (settingStore.setting.useCorrectionText) {
    //         var arr = R.reverse(audioRecord.data?.data.recordEntries ?? [])
    //         for (var i = 0; i < arr.length; i++) {
    //             var x = arr[i]
    //             if ((result + x.text).length > settingStore.setting.maxTextCount)
    //                 break;
    //             result = x.text + " " + result
    //             if (x.translatedTexts) {
    //                 var idx = R.findIndex(x.translatedTexts ?? [], r => r.language == translatedLanguage)
    //                 if (idx >= 0)
    //                     translated = x.translatedTexts[idx].text + " " + translated
    //             }
    //         }
    //     }
    //     if (settingStore.setting.useRealtimeText)
    //         result += "\n" + realtimeText
    //     return { text: result, translated: translated };
    // }, [settingStore.setting.useRealtimeText, settingStore.setting.useCorrectionText, realtimeText, audioRecord.data?.data, translatedLanguage, settingStore.setting.maxTextCount])
    const { hovered, ref } = useHover();
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/audioRecordHub")
            .withAutomaticReconnect()
            .withStatefulReconnect()
            .build();
        SetConnection(connection)
        connection.on("OnUpdateEntry", async (meta: DataUpdateMeta, entry: AudioRecordEntry) => {
            await queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnDeleteEntry", (meta: DataUpdateMeta, entryId: string) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnUpdate", (meta: DataUpdateMeta) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnRealtimeText", (audioRecordId: string, text: string) => {
            // setRealtimeText(text)
        })
        connection.on("OnUpdateRecordingHeartbeat", (audioRecordId: string, timestamp: number) => {
            set_recording_heartbeat(timestamp)
            if (timestamp <= 0) queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection
            .start()
            .then(async () => {
                console.log('SignalR Connected!');
                var success = await connection.invoke<boolean>("ListenAudioRecordEvent", workspaceId, audioRecordId)
                if (success)
                    console.log("ListenAudioRecordEvent success")
                else
                    console.warn("ListenAudioRecordEvent fail")
            })
            .catch((err) => {
                console.log('SignalR Connection Error: ', err);
            });
        setHubConnection(connection)
        return () => {
            if (connection) {
                connection
                    .stop()
                    .then(() => {
                        console.log('SignalR Disconnected!');
                    })
                    .catch((err) => {
                        console.log('SignalR Disconnection Error: ', err);
                    });
            }
        };
    }, [])
    return <Box h="100vh" style={{ backgroundColor: settingStore.setting.backgroundColor }}>
        <Flex m={0} p={30} h={120} ref={ref as RefObject<HTMLDivElement>} justify="center" >
            <Transition
                mounted={hovered || dropDownOpenCount > 0 || false}
                transition="fade"
                duration={400}
                keepMounted={true}
                timingFunction="ease"
            >
                {(styles) =>
                    <Flex style={{ ...styles }} h="100%" direction="column" gap={10}>
                        <Group>
                            <Group gap={5}>
                                <Text>
                                    最大顯示數量
                                </Text>
                                <Select w={80} data={["1", "2", "3", "4", "5", "6", "7", "8"]} value={settingStore.setting.maxTextLine?.toString()} onDropdownOpen={() => setDropDownOpenCount(x => x + 1)} onDropdownClose={() => setDropDownOpenCount(x => x - 1)} onChange={e => {
                                    var value: number = Number(e)
                                    settingStore.Update(x => x.maxTextLine = value)
                                }} />
                            </Group>
                            <Group gap={5}>
                                <Text>
                                    背景
                                </Text>
                                <ColorInput w={130} value={settingStore.setting.backgroundColor} onChange={v => { settingStore.Update(x => x.backgroundColor = v) }} onFocus={() => setDropDownOpenCount(x => x + 1)} onBlur={() => setDropDownOpenCount(x => x - 1)} />
                            </Group>
                            {/* <Switch
                                checked={settingStore.setting.useRealtimeText}
                                onChange={(event) => settingStore.Update(x => x.useRealtimeText = event.target.checked)}
                                label="即時字幕"
                            /> */}
                            {/* <Switch
                                checked={settingStore.setting.useCorrectionText}
                                onChange={(event) => settingStore.Update(x => x.useCorrectionText = event.target.checked)}
                                label="校正字幕"
                            /> */}
                            <Button leftSection={<IconRestore />} radius="xl" variant="light" onClick={() => {
                                settingStore.Reset()
                            }}>
                                重設
                            </Button>
                            <Button leftSection={<IconArrowBackUp />} radius="xl" variant="light" onClick={() => {
                                navigate({
                                    to: "/workspace/$workspaceId/audioRecord/$audioRecordId", params: {
                                        workspaceId: workspaceId, audioRecordId: audioRecordId
                                    }
                                })
                            }}>
                                一般檢視
                            </Button>
                        </Group>
                        <Group>
                            <Group gap={5}>
                                <Text>
                                    主字幕
                                </Text>
                                <Switch
                                    checked={settingStore.setting.showText}
                                    onChange={(event) => settingStore.Update(x => x.showText = event.target.checked)}
                                />
                                <Select w={90} data={["16", "20", "24", "26", "28", "32", "36", "48", "72"]} value={settingStore.setting.fontSize.toString()} leftSection={<IconTextSize />} onDropdownOpen={() => setDropDownOpenCount(x => x + 1)} onDropdownClose={() => setDropDownOpenCount(x => x - 1)} onChange={e => {
                                    var value: number = Number(e)
                                    settingStore.Update(x => x.fontSize = value)
                                }} />
                                <ColorInput w={130} value={settingStore.setting.textColor} onChange={v => { settingStore.Update(x => x.textColor = v) }} onFocus={() => setDropDownOpenCount(x => x + 1)} onBlur={() => setDropDownOpenCount(x => x - 1)} />
                            </Group>
                            {
                                audioRecord.data?.data && translatedTexts.length > 0 &&
                                <Group gap={5}>
                                    <Text>
                                        翻譯字幕
                                    </Text>
                                    <TranslatedLanguageSelect
                                        selectProps={{ size: "xs", withCheckIcon: false, w: "110px", placeholder: "選擇語言", allowDeselect: false, leftSection: <IconLanguageHiragana />, onDropdownOpen: () => setDropDownOpenCount(x => x + 1), onDropdownClose: () => setDropDownOpenCount(x => x - 1) }}
                                        deleteButtonProps={{ size: "compact-xs" }}
                                        audioRecord={audioRecord.data.data} onSelectLanguage={l => {
                                            setTranslatedLanguage(l)
                                        }} />
                                    <Select w={90} data={["16", "20", "24", "26", "28", "32", "36", "48", "72"]} value={settingStore.setting.translateFontSize.toString()} leftSection={<IconTextSize />} onDropdownOpen={() => setDropDownOpenCount(x => x + 1)} onDropdownClose={() => setDropDownOpenCount(x => x - 1)} onChange={e => {
                                        var value: number = Number(e)
                                        settingStore.Update(x => x.translateFontSize = value)
                                    }} />
                                    <ColorInput w={130} value={settingStore.setting.translateTextColor} onChange={v => { settingStore.Update(x => x.translateTextColor = v) }} onFocus={() => setDropDownOpenCount(x => x + 1)} onBlur={() => setDropDownOpenCount(x => x - 1)} />
                                </Group>
                            }
                        </Group>
                    </Flex>
                }
            </Transition>
        </Flex>
        <Stack p={50} gap={30} align="center" justify="center">
            {isRecording && <>
                <Loader type="dots" />
                <Stack w="100%" gap={1.5}>
                    {settingStore.setting.showText &&
                        <>
                            <AnimatePresence>
                                {R.takeLast(recordEntries, settingStore.setting.maxTextLine).map((entry, idx) =>
                                    <Item key={entry.id}>
                                        <Text ta="center" size={`${settingStore.setting.fontSize}px`} c={settingStore.setting.textColor} lh="1.5" style={{ whiteSpace: "pre-wrap" }}>
                                            {
                                                entry.text
                                            }
                                        </Text>
                                    </Item>
                                )}
                            </AnimatePresence>
                        </>
                    }
                </Stack>
                <Stack w="100%" gap={1.5}>
                    {!!translatedLanguage &&
                        <>
                            <AnimatePresence>
                                {R.takeLast(recordEntries, settingStore.setting.maxTextLine).map((entry, idx) =>
                                    <Item key={entry.id}>
                                        <Text ta="center" size={`${settingStore.setting.translateFontSize}px`} c={settingStore.setting.translateTextColor} lh="1.5" style={{ whiteSpace: "pre-wrap" }}>
                                            {entry.translatedTexts?.find(x => x.language == translatedLanguage)?.text ?? ""}
                                        </Text>
                                    </Item>
                                )}
                            </AnimatePresence>
                        </>
                    }
                </Stack>
            </>}
        </Stack>
    </Box>
}
function Item(props: PropsWithChildren) {
    const isPresent = useIsPresent();
    return (
        <motion.div style={{ position: isPresent ? "static" : "absolute", margin: 0 }} initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -100 }}
            transition={{ type: "keyframes" }}
            layout>
            {props.children}
        </motion.div>
    );
}
