import { Button, Card, Group, Text, Stack, CopyButton, Tooltip, ActionIcon, rem } from "@mantine/core"
import { useNavigate, useParams } from "@tanstack/react-router"
import { CreditKeyList } from "./CreditKey";
import { PeriodCreditKeyList } from "./PeriodCreditKey";
import { ChangeUserPassword } from "./ChangeUserPassword";
import { AdminAppKeyManager } from "./AppKey";
import { PageTitle } from "@/Components/PageTitle";
import { getApiAdminUserManagerGetUser, useGetApiAdminUserManagerGetUser } from "@/API";
import { IconCopy } from "@tabler/icons-react";

export function UserInfo() {
    const navigate = useNavigate()
    const { userId } = useParams({ from: "/admin/UserManager/$userId" })
    const user = useGetApiAdminUserManagerGetUser({ userId: userId })
    return <>
        <PageTitle text="使用者資料" />
        <Group gap={20}>
            <Text fw={500} size="xl">
                使用者ID
            </Text>
            <Group gap={0}>
                <Text fw={500} size="xl">
                    {userId}
                </Text>
                <CopyButton value={userId ?? ""}>
                    {({ copied, copy }) => (
                        <Tooltip label={copied ? '已複製' : ''} disabled={!copied} withArrow>
                            <ActionIcon size="xl" variant="transparent" onClick={copy}>
                                <IconCopy style={{ width: rem(24), height: rem(24) }} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </CopyButton>
            </Group>
        </Group>
        <Group gap={20}>
            <Text fw={500} size="xl">
                使用者帳號
            </Text>
            <Group gap={0}>
                <Text fw={500} size="xl">
                    {user.data?.data.userName}
                </Text>
                <CopyButton value={user.data?.data.userName ?? ""}>
                    {({ copied, copy }) => (
                        <Tooltip label={copied ? '已複製' : ''} disabled={!copied} withArrow>
                            <ActionIcon size="xl" variant="transparent" onClick={copy}>
                                <IconCopy style={{ width: rem(24), height: rem(24) }} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </CopyButton>
            </Group>
        </Group>
        <Group gap={20}>
            <Text fw={500} size="xl">
                顯示名稱
            </Text>
            <Group gap={0}>
                <Text fw={500} size="xl">
                    {user.data?.data.displayName && user.data?.data.displayName != "" ? user.data?.data.displayName : "無"}
                </Text>
            </Group>
        </Group>

        <Stack mt={10}>
            <PageTitle text="管理" />
            <ChangeUserPassword userId={userId} />
            <Button w={150} onClick={() => {
                navigate({
                    to: "/admin/TTSUsage",
                    search: { userId: userId }
                })
            }}>使用狀況查詢</Button>
            <PageTitle text="儲值金鑰" />
            <PeriodCreditKeyList userId={userId} />
            <CreditKeyList userId={userId} />
            <PageTitle text="授權金鑰" />
            <AdminAppKeyManager userId={userId} />
        </Stack>
    </>
}