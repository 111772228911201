import { usePutApiAdminWorkspaceSetOwner } from "@/API";
import { Autocomplete, Button, Group, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { PropsWithChildren, useState } from "react";

export function AdminSetOwnerButton(props: { workspaceId: string, defaultUserNames?: string[], onResult?: () => void } & PropsWithChildren) {
    const [opened, { open, close }] = useDisclosure(false);
    const SetOwner_Mutation = usePutApiAdminWorkspaceSetOwner()

    return <>
        <div onClick={open}>
            {props.children}
        </div>
        <Modal opened={opened} onClose={close} title="轉移擁有者">
            <AdminSetOwnerForm defaultUserNames={props.defaultUserNames ?? []} onResult={async (v) => {
                try {
                    var result = await SetOwner_Mutation.mutateAsync({
                        params: { workspaceId: props.workspaceId, userName: v }
                    })
                    notifications.show({ message: "修改成功" })
                    props.onResult?.();
                    close();
                } catch (e) {
                    notifications.show({ message: "修改失敗", color: "red" })
                }

            }} />
        </Modal>
    </>
}
function AdminSetOwnerForm(props: { defaultUserNames: string[], onResult: (name: string) => void }) {
    const [value, setValue] = useState('');
    return <>
        <Autocomplete data={props.defaultUserNames} value={value} onChange={setValue} />
        <Group justify="flex-end" mt="md">
            <Button onClick={() => props.onResult(value)}>確認</Button>
        </Group>
    </>
}