import { AudioRecord, getGetApiAudioRecordGetQueryKey, getGetApiAudioRecordListQueryKey, usePutApiAudioRecordUpdatePublicPermission, Workspace } from "@/API"
import { ShareRecordButton } from "./ShareRecordUI"
import { PropsWithChildren, useMemo, useState } from "react"
import { AudioRecordPublicPermissionToLevel, GetAudioRecordPublicPermission } from "../AudioRecordPublicPermissionText"
import { GetAudioRecordUrl } from "@/APIURL"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { UseWorkspaceAuth } from "../UseWorkspaceAuth"

export function AudioRecordShareRecordButton({ workspace, audioRecord, editable = true, children }: { workspace: Workspace, audioRecord: AudioRecord, editable?: boolean } & PropsWithChildren) {
    const queryClient = useQueryClient()
    const editorAuth = UseWorkspaceAuth(workspace.id ?? "", "Editor")
    const editPermission_Mutation = usePutApiAudioRecordUpdatePublicPermission()
    const [updateAudioRecordLoading, setUpdateAudioRecordLoading] = useState(false)
    const publicPermission = useMemo(() => {
        return GetAudioRecordPublicPermission(audioRecord?.publicPermission ?? "None", workspace?.publicPermission ?? "None")
    }, [audioRecord?.publicPermission, workspace?.publicPermission])
    const useWorkspacePublicPermission = useMemo(() => {
        return AudioRecordPublicPermissionToLevel(workspace?.publicPermission ?? "None") > AudioRecordPublicPermissionToLevel(audioRecord?.publicPermission ?? "None")
    }, [audioRecord?.publicPermission, workspace?.publicPermission])
    return <>
        <ShareRecordButton title="分享逐字稿" url={window.origin + GetAudioRecordUrl(audioRecord.workspaceId!, audioRecord.id!)} publicPermission={publicPermission ?? "None"} workspaceUsers={workspace.users ?? []} name={audioRecord.name ?? ""} enableChangePublicPermission={editorAuth && editable && !useWorkspacePublicPermission}
            publicPermissionTips={useWorkspacePublicPermission ? "已繼承工作空間權限" : ""}
            changePublicPermissionLoading={updateAudioRecordLoading}
            onPublicPermission={async p => {
                setUpdateAudioRecordLoading(true)
                try {
                    if (audioRecord.workspaceId && audioRecord.id) {
                        try {
                            await editPermission_Mutation.mutateAsync({ params: { workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id, permission: p } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordGetQueryKey({ workspaceId: workspace.id!, audioRecordId: audioRecord.id }) })
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                }
                catch (e) {

                }
                setUpdateAudioRecordLoading(false)
            }}>
            {children}
        </ShareRecordButton>
    </>
}