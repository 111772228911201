import { WorkspaceManager } from '@/Admin/WorkspaceManager'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/admin/WorkspaceManager/')({
  component: RouteComponent,
})

function RouteComponent() {
  return <WorkspaceManager/>
}
