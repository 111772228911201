import Icon from 'Images/banner.png'
import { postApiAdminAuthLogin } from "@/API";
import { useAuthStore } from "@/Stores/AuthStore";
import { Text, Image, Button, Group, Paper, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { Link, useNavigate } from "@tanstack/react-router";

export function AdminLoginPage() {
    const navigate = useNavigate()
    const auth = useAuthStore()
    const form = useForm({
        initialValues: {
            userName: '',
            password: ''
        },
        validate: {
            // password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <Stack align="center">
            <Group justify="center" mt={20} mb={20}>
                <Link to='/'>
                    <Image
                        radius="md"
                        src={Icon}
                        w={300}
                    />
                </Link>
            </Group>
            <Paper shadow="sm" p="xl" radius="lg" w={500}>
                <Text ta="center" fw={500} mb={20}>
                    {BUILD_PRODUCT} - 管理員登入
                </Text>
                <form onSubmit={form.onSubmit(async (x) => {
                    auth.SetUpdating(true)
                    try {
                        var result = await postApiAdminAuthLogin(x)
                        if (result.data.userName && result.data.role) {
                            auth.Update(result.data)
                            notifications.show({
                                message: '登入成功',
                            })
                            navigate({ to: "/" })
                        }
                    } catch (e) {
                        notifications.show({
                            message: '登入失敗', color: "red"
                        })
                    }
                    auth.SetUpdating(false)
                })}>
                    <Stack>
                        <TextInput
                            required
                            placeholder="管理者帳號"
                            value={form.values.userName}
                            onChange={(event) => form.setFieldValue('userName', event.currentTarget.value)}
                            radius="md"
                        />

                        <PasswordInput
                            required
                            placeholder="管理者密碼"
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password && 'Password should include at least 6 characters'}
                            radius="md"
                        />
                    </Stack>

                    <Group justify="center" mt="xl">
                        <Button type="submit" radius="md">
                            登入
                        </Button>
                    </Group>
                </form>
            </Paper>
        </Stack>
    );
}